import React from "react"

/**
 * 1) Define your features in an array of objects.
 *    Each feature has an icon, title, and description.
 */
const featuresData = [
    {
        icon: iconBrain(),
        title: "Smart AI Engine",
        desc: "Generates highly relevant questions using ChatGPT for maximum impact.",
    },
    {
        icon: iconLightning(),
        title: "Fast & Instant",
        desc: "Get unique questions in seconds—no more long prep times.",
    },
    {
        icon: iconTarget(),
        title: "Tailored Output",
        desc: "Based on job title, seniority, & description for precise relevance.",
    },
    {
        icon: iconTime(),
        title: "Saves Time",
        desc: "No need to spend hours formulating answers. Get well-structured responses quickly.",
    },
    {
        icon: iconRole(),
        title: "Role-Specific Answers",
        desc: "Responses are generated based on the job role, company, and JD for maximum relevance.",
    },
    {
        icon: iconConfidence(),
        title: "Improves Confidence",
        desc: "Provides polished, professional answers to help you articulate thoughts better.",
    },
    {
        icon: iconParallel(),
        title: "Parallel Answer Display",
        desc: "Generates two different responses per question, so you can pick the best one.",
    },
    {
        icon: iconNoSignUp(),
        title: "No Sign-Up Required",
        desc: "Use the tool instantly—no registration or login needed to start generating questions right away.",
    },
]

export default function FeaturesSection() {
    return (
        <section style={styles.sectionWrapper}>
            <h2 style={styles.sectionTitle}>Why Our AI Tool?</h2>
            <p style={styles.sectionSubtitle}>
                Discover the powerful benefits and time-saving capabilities that
                make our tool a must-have for interview preparation.
            </p>

            {/* Responsive grid of feature cards */}
            <div style={styles.gridWrapper}>
                {featuresData.map((feature, idx) => (
                    <div key={idx} style={styles.featureCard}>
                        <div style={styles.iconCircle}>{feature.icon}</div>
                        <h3 style={styles.featureTitle}>{feature.title}</h3>
                        <p style={styles.featureDesc}>{feature.desc}</p>
                    </div>
                ))}
            </div>
        </section>
    )
}

/* ---------------------------
 *   Inline SVG Icon Helpers
 * --------------------------- */
function iconBrain() {
    return (
        <svg
            width="24"
            height="24"
            fill="none"
            stroke="#4F46E5"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M15 8a3 3 0 0 0-6 0v8a3 3 0 0 0 6 0z" />
            <path d="M20 8a3 3 0 0 0-6 0v8a3 3 0 0 0 6 0z" />
            <path d="M4 8a3 3 0 0 1 6 0v8a3 3 0 0 1-6 0z" />
        </svg>
    )
}

function iconLightning() {
    return (
        <svg
            width="24"
            height="24"
            fill="none"
            stroke="#4F46E5"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M13 2L3 14h9l-1 8L21 10h-9l1-8z" />
        </svg>
    )
}

function iconTarget() {
    return (
        <svg
            width="24"
            height="24"
            fill="none"
            stroke="#4F46E5"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <circle cx="12" cy="12" r="10" />
            <circle cx="12" cy="12" r="3" />
            <path d="M12 2v4" />
            <path d="M12 18v4" />
            <path d="M20 12h4" />
            <path d="M2 12H6" />
        </svg>
    )
}

function iconTime() {
    return (
        <svg
            width="24"
            height="24"
            fill="none"
            stroke="#4F46E5"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <circle cx="12" cy="12" r="10" />
            <polyline points="12 6 12 12 16 14" />
        </svg>
    )
}

function iconRole() {
    return (
        <svg
            width="24"
            height="24"
            fill="none"
            stroke="#4F46E5"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <circle cx="9" cy="7" r="4" />
            <path d="M17 11v2a4 4 0 0 1-4 4H5a4 4 0 0 1-4-4v-2" />
            <circle cx="17" cy="7" r="4" />
            <path d="M23 11v2a4 4 0 0 1-4 4h-3" />
        </svg>
    )
}

function iconConfidence() {
    return (
        <svg
            width="24"
            height="24"
            fill="none"
            stroke="#4F46E5"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M5 20l2-2.5c2.5 2 5.5 2 8 0l2 2.5" />
            <circle cx="12" cy="10" r="3" />
            <circle cx="12" cy="12" r="10" />
        </svg>
    )
}

function iconParallel() {
    return (
        <svg
            width="24"
            height="24"
            fill="none"
            stroke="#4F46E5"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M10 3H3v7" />
            <path d="M21 3h-7v7" />
            <path d="M10 14v7H3" />
            <path d="M21 14v7h-7" />
        </svg>
    )
}

function iconNoSignUp() {
    return (
        <svg
            width="24"
            height="24"
            fill="none"
            stroke="#4F46E5"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <rect x="2" y="4" width="20" height="16" rx="2" ry="2" />
            <line x1="2" y1="10" x2="22" y2="10" />
            <path d="M8 4V2h8v2" />
        </svg>
    )
}

/* ---------------------------
 *    Inline Styles Object
 * --------------------------- */
const styles: { [key: string]: React.CSSProperties } = {
    sectionWrapper: {
        maxWidth: "1200px",
        margin: "0 auto",
        padding: "2rem 1rem",
        textAlign: "center",
    },
    sectionTitle: {
        fontSize: "2rem",
        fontWeight: 700,
        marginBottom: "0.5rem",
    },
    sectionSubtitle: {
        fontSize: "1rem",
        color: "#555",
        marginBottom: "2rem",
        lineHeight: "1.6",
        maxWidth: "700px",
        margin: "0.5rem auto 2rem",
    },
    gridWrapper: {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
        gap: "1.5rem",
        justifyItems: "center",
        alignItems: "start",
    },
    featureCard: {
        backgroundColor: "#fff",
        borderRadius: "12px",
        boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
        padding: "1.5rem",
        maxWidth: "300px",
        textAlign: "center",
        transition: "transform 0.2s ease",
    },
    iconCircle: {
        width: "48px",
        height: "48px",
        margin: "0 auto",
        borderRadius: "50%",
        background: "#EDE9FE",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "1rem",
    },
    featureTitle: {
        fontSize: "1.1rem",
        fontWeight: 600,
        marginBottom: "0.5rem",
    },
    featureDesc: {
        fontSize: "0.95rem",
        color: "#555",
        lineHeight: "1.4",
    },
}

/*
   Usage:
   -------
   1) Import <FeaturesSection /> into your page or main component.
   2) Place <FeaturesSection /> where you want these feature cards to appear.
   3) Adjust styling, icons, or text to your liking.
*/
